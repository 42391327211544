<template>
  <div class="container" id="timecourse">

    <div class="time-list-box">
      <div class="time-list-title">競賽時程</div>
      <div class="time-list-content">
        <div class="list">
          <div class="date">
            2025.04.01 (二)
            <div class="circle">
              <div class="c_box">
                <img class="light1" src="../assets/images/2025/table_circle1_light@2x.webp" alt="">
                <img
                  src="../assets/images/2025/table_circle1@2x.webp"
                  alt="table_circle"
                  srcset=""
                />
              </div>
              
            </div>
          </div>
          <div class="list-content">
            <div class="list-title">競賽啟動 | 開始收件</div>
            <div class="content">
              <b>本競賽為邀賽制，僅供收到邀請公司參加<br></b>
              由企業聯絡人將備審資料上傳至網站即可 <br>
              兩份資料：1.報名表2.不限格式之公司簡介
            </div>
          </div>
        </div>

        <div class="list">
          <div class="date">
            2025.06.30 (一)
            <span class="date-small">下午5:00前</span>
            <div class="circle">
              <div class="c_box">
                <img class="light" src="../assets/images/2025/table_circle_light@2x.webp" alt="">
                <img
                  src="../assets/images/2025/table_circle1@2x.webp"
                  alt="table_circle"
                  srcset=""
                />
              </div>
              
            </div>
          </div>
          <div class="list-content">
            <div class="list-title no-content-title">繳件截止</div>
            <!-- <div class="content" v-if="!isMobile"></div> -->
          </div>
        </div>

        <div class="list">
          <div class="date">
            2025.07.15 (二)
            <span class="date-small">下午5:00前</span>
            <div class="circle">
              <div class="c_box">
                <img class="light" src="../assets/images/2025/table_circle_light@2x.webp" alt="">
                <img
                  src="../assets/images/2025/table_circle1@2x.webp"
                  alt="table_circle"
                  srcset=""
                />
              </div>
              
            </div>
          </div>
          <div class="list-content">
            <div class="list-title">初選結果公佈</div>
            <div class="content">
              評審委員依評選辦法評分是否入選(一組選10家，共20家) * 此階段將針對入圍公司(各組前十名)進行是否有不良商業紀錄之審核，若發生有公司被取消資格狀況，將依名次遞補
            </div>
          </div>
        </div>

        <div class="list">
          <div class="date sp" id="date-box">
            <div class="date-1" v-if="!isMobile">
              2025.08.05 (二)
              <div class="circle">
                <div class="c_box">
                  <img class="light" src="../assets/images/2025/table_circle_light@2x.webp" alt="">
                  <img
                    src="../assets/images/2025/table_circle1@2x.webp"
                    alt="table_circle"
                    srcset=""
                  />
                </div>
                
              </div>
            </div>
            <div class="date-2" v-if="!isMobile">
              2025.08.06 (三)
              <div class="circle">
                <div class="c_box">
                  <img class="light" src="../assets/images/2025/table_circle_light@2x.webp" alt="">
                  <img
                    src="../assets/images/2025/table_circle1@2x.webp"
                    alt="table_circle"
                    srcset=""
                  />
                </div>
                
              </div>
            </div>
            <div class="date-moblie" v-if="isMobile">
              2025.08.05 (二)國際組<br>2024.08.06 (三)永續組
            </div>
          </div>
          <div class="list-content">
            <div class="list-title">決賽 | 實體簡報</div>
            <div class="content">
              入選企業簡報，每組10分鐘＋評審詢答15分鐘＝25分鐘。採【實體簡報】方式進行，屆時會再提供詳細之議程內容
            </div>
          </div>
        </div>

        <div class="list">
          <div class="date" id="date-box">
            <div class="date-1" v-if="!isMobile">
              2025.08.20 (三) <br />
              <div class="date-text">~</div>
              <div class="circle">
                <div class="c_box">
                  <img class="light" src="../assets/images/2025/table_circle_light@2x.webp" alt="">
                  <img
                    src="../assets/images/2025/table_circle1@2x.webp"
                    alt="table_circle"
                    srcset=""
                  />
                </div>
                
              </div>
            </div>
            <div class="date-2" v-if="!isMobile">
              2025.09.01 (一)
              <div class="circle">
                <div class="c_box">
                  <img class="light" src="../assets/images/2025/table_circle_light@2x.webp" alt="">
                  <img
                    src="../assets/images/2025/table_circle1@2x.webp"
                    alt="table_circle"
                    srcset=""
                  />
                </div>
                
              </div>
            </div>

            <div class="date-moblie" v-if="isMobile">
              2025.08.20 (三)-2025.09.01 (一)
            </div>
          </div>
          <div class="list-content">
            <div class="list-title">決賽 | 企業實地訪查</div>
            <div class="content">由評審委員組成的工作小組進行企業參訪</div>
          </div>
        </div>

        <div class="list">
          <div class="date">
            2025.10.01 (三)
            <div class="circle">
              <div class="c_box">
                <img class="light" src="../assets/images/2025/table_circle_light@2x.webp" alt="">
                <img
                  src="../assets/images/2025/table_circle1@2x.webp"
                  alt="table_circle"
                  srcset=""
                />
              </div>
              
            </div>
          </div>
          <div class="list-content">
            <div class="list-title">頒獎典禮暨茶會</div>
            <div class="content">
              邀請進入決賽之企業出席，屆時會再提供詳細之內容
            </div>
          </div>
        </div>
      </div>
    </div>

    <picture>
      <source media="(max-width: 1024px)" srcset="../assets/images/2025/p6_img1_ph@2x.webp">
      <img class="time-bg2" src="../assets/images/2025/p6_img1@2x.webp" alt="time_bg2" srcset="" />
    </picture>
    
  </div>
</template>

<script>
export default {
  name: "TimeCourse",

  data() {
    return {
      isMobile: true,
    };
  },

  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 1024;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_mixin.scss";
.container {
  width: 100%;
  position: relative;
  //   height: 100vh;
  display: flex;
  margin-top: 3vw;
  // overflow: hidden;

  @media all and (max-width: 1024px) {
    margin-bottom: 14vw;
    padding-bottom: 20vw;
    margin-top: 10vw;
  }
  .time-bg {
    position: absolute;
    left: 0px;
    width: 25%;

    @media all and (max-width: 1440px) {
      top: 5vw;
    }
  }

  .time-list-box {
    display: flex;
    font-family: "Noto Sans TC";
    flex-direction: column;
    margin: 0 auto;
    margin-top: 6vw;
    width: 50%;
    margin-bottom: 6vw;
    z-index: 1;
    @media all and (max-width: 1024px) {
      width: 85%;
    }
    .time-list-title {
      color: #fff;
      font-size: 36pt;
      font-size: 1.5vw;
      letter-spacing: 0.15em;
      background: url(../assets/images/2025/new_title_bg@2x.webp);
      background-size:cover;
      text-align: center;
      line-height: 3.4vw;
      border-radius: 0.5vw 0.5vw 0px 0px;
      margin-bottom: 0.3vw;

      @media all and (max-width: 1024px) {
        font-size: 20px;
        line-height: 10vw;
        border-radius: 1.5vw 1.5vw 0px 0px;
        margin-bottom: 1vw;
      }
    }

    .time-list-content {
      width: 100%;
      background-image: url(../assets/images/2025/table_bg@2x.webp);
      background-size: cover;
      display: flex;
      flex-direction: column;
      padding: 1vw 2vw;
      padding-bottom: 2vw;
      border-radius: 0px 0px 0.5vw 0.5vw;

      @media all and (max-width: 1024px) {
        border-radius: 0px 0px 1.5vw 1.5vw;
        padding: 0vw;
        background-image: none;
      }

      .list {
        display: flex;
        padding-top: 1vw;

        @media all and (max-width: 1024px) {
          flex-direction: column;
          padding-top: 0vw;
        }
        .date {
          @include dateText();
          color: #fff;
          width: 28.4%;
          display: flex;
          justify-content: space-between;
          position: relative;
          @media all and (max-width: 1920px) {
            width: 29%;
          }

          @media all and (max-width: 1024px) {
            background-color: #262626;
            width: 100%;
            text-align: center;
            justify-content: center;
            line-height: 11vw;
            flex-direction: column;
          }

          .date-small {
            position: absolute;
            @include content();
            color: #e7dd67;
            top: 1.5vw;
            left: 2vw;

            @media all and (max-width: 1920px) {
              left: 1vw;
            }

            @media all and (max-width: 1024px) {
              position: relative;
              text-align: center;
              top: 0vw;
              left: 0vw;
              margin-top: -3vw;
              margin-bottom: 2vw;
              font-size: 3.3vw;
            }
          }
          .circle {
            position: relative;

            &::before{
              content: "";
              position: absolute;
              top: 5%;
              left: 50%;
              width: 1px;
              height: 296%;
              background-color: #fff;
            }

            .light1{
              position: absolute;
              width: 500%;
              top: -100%;
              left: -202%;
              mix-blend-mode: screen;
              opacity: 1;

              @media (max-width: 1920px) {
                top: -140%;
              }
            }

            .c_box{
              position: relative;
              .light{
                position: absolute;
                width: 107%;
                top: -120%;
                mix-blend-mode: screen;

                @media (max-width: 1920px) {
                top: -140%;
              }
              }
            }
            


            img {
              width: 20px;

              @media all and (max-width: 1920px) {
                width: 1vw;
              }

              @media all and (max-width: 1024px) {
                display: none;
              }
            }
          }

          &.sp{
            justify-content: flex-start;

            .date-1{
              margin-bottom: 1.1vw !important;
            }
          }
        }

        &:last-of-type{
          .circle{
            &::before{
              display: none;
            }
          }
        }

        #date-box {
          display: flex;
          flex-direction: column;
          position: relative;
          .date-1,
          .date-2 {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1vw;
          }

          .date-1 {
            position: relative;
            margin-bottom: 2vw;
            .date-text {
              position: absolute;
              top: 1.7vw;
              left: 2.5vw;
            }
          }
        }

        .list-content {
          width: 71.6%;
          padding-left: 2.5vw;
          @media all and (max-width: 1024px) {
            background: linear-gradient(to right, #867c67 0%,#665a49 15%,#190900 70%,#000000 100%);

            width: 100%;
            padding-left: 0vw;
            text-align: center;
          }
          .list-title {
            color: #fbf1b9;
            letter-spacing: 0.1em;
            font-size: 24pt;
            font-size: 1vw;
            font-family: "微軟正黑體";
            font-weight: bold;
            padding-bottom: 0.3vw;

            @media all and (max-width: 1440px) {
              font-size: 18px;
            }

            @media all and (max-width: 1024px) {
              margin-top: 5vw;
              // margin-bottom: 4vw;
            }
          }

          .no-content-title {
            color: #fbf1b9;
            letter-spacing: 0.1em;
            font-size: 24pt;
            font-size: 1vw;
            font-family: "微軟正黑體";
            font-weight: bold;
            padding-bottom: 1vw;
            border-bottom: 1px solid #edcc98;
            @media all and (max-width: 1440px) {
              font-size: 18px;
              border-bottom: none;
              padding-bottom: 5vw;
            }
          }

          .content {
            @include content();
            padding-bottom: 1vw;
            border-bottom: 1px solid #fbf1b9;

            @media all and (max-width: 1024px) {
              padding: 4vw 3vw;
              border-bottom: none;
            }

            b{
              color: #ffacbc;
            }
          }
        }
      }
    }
  }

  .time-bg2 {
    position: absolute;
    right: -2vw;
    width: 127%;
    bottom: -27%;
    z-index: 0;

    @media all and (max-width: 1024px) {
      width: 100%;
      right: 2%;
      bottom: -11%;
    }
  }
}
</style>
