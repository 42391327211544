<template>
  <div class="container">
    <div
      class="iframe-box"
      v-for="(item, index) in ytVideo"
      :key="index"
      :item="item"
    >
      <div class="yt_box" v-html="item.yt_url"></div>
    </div>

    <img class="c2-bg" src="../assets/images/2025/p3_img1@2x.webp" alt="c2_bg" srcset="" />
    <div class="news-box">
      <div class="news-title">最新消息</div>
      <div class="news-content">
        <div
          class="news-list"
          v-for="(item, index) in newsInfo"
          :key="index"
          :item="item"
          :data-tb-index="item.Tb_index"
          @click="onEnterNewsPage(item.Tb_index)"
        >
          <div class="news-a">
            <div class="news-date">{{ item.StartDate }}</div>
            <div class="news-text">
              {{ item.aTitle_one }}
            </div>
            <div class="news-icon">
              <img src="../assets/images/arrow.svg" alt="" />
            </div>
          </div>
        </div>

        <img
          class="news-bg"
          src="../assets/images/news_bg_1.png"
          alt="news_bg_1"
          srcset=""
        />
      </div>
    </div>
    <!-- <img class="c2-bg2" src="../assets/images/c2_bg2.png" alt="" srcset="" /> -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "YtNews",
  data() {
    return {
      newsInfo: [{}],
      newsTbIndex: "",
      ytVideo: [{}],
    };
  },
  mounted() {
    this.getNewdInfo();
    this.getYTvideo();
  },
  methods: {
    getYTvideo() {
      axios
        .get(
          "https://back-longterm.srl.tw/ajax/index.php?type=get_introduce_video",
          {}
        )
        .then((res) => {
          this.ytVideo = res.data.data;
          console.log(this.ytVideo);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getNewdInfo() {
      axios
        .get("https://back-longterm.srl.tw/ajax/index.php?type=get_news", {})
        .then((res) => {
          this.newsInfo = Object.freeze(res.data).data;

          // console.log(Object.freeze(res.data).data, "freeze");

          this.newsTbIndex = this.newsInfo.Tb_index;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    onEnterNewsPage(Tb_index) {
      // console.log("Clicked on news with Tb_index:", Tb_index);
      this.$router.push({ name: "NewsContent", params: { Tb_index } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_mixin.scss";

.container {
  width: 100%;
  position: relative;

  // overflow: hidden;

  :deep(iframe) {
    width: 50%;
    height: 54vh;

    @media all and (max-width: 1024px) {
      width: 85%;
      height: 22vh;
    }
  }
  .iframe-box {
    margin: 0 auto;
    width: 100%;
    margin-top: 6vw;
    z-index: 3;
    text-align: center;
    position: relative;
   

    .yt_box{
      display: inline-block;
      width: 50%;
      height: 54vh;
      background: linear-gradient(to right, #f9ee9a 0%, #c3963f 20%, #fff48f 42%, #97560d 71%, #f6ce65 100%);
      padding: 0.2%;

      @media (max-width: 1024px) {
        width: 85%;
        height: 50vw;
        padding: 0.4%;
      }

      :deep(iframe) {
          width: 100%;
          height: 100%;
      }
    }
  }

  .c2-bg {
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 33%;
    left: -13%;
  }

  .c2-bg2 {
    position: absolute;
    width: 27%;
    z-index: -1;
    bottom: -7vw;
    opacity: 0.7;
    right: 0px;
  }
  .news-box {
    position: relative;
    z-index: 2;
    display: flex;
    font-family: "Noto Sans TC";
    flex-direction: column;
    margin: 8vw auto;
    width: 50%;
    @media all and (max-width: 1024px) {
      width: 85%;
    }
    .news-title {
      color: #fff;
      font-size: 1.5vw;
      letter-spacing: 0.15em;
      background: url(../assets/images/2025/new_title_bg@2x.webp);
      background-size: cover;
      text-align: center;
      line-height: 3.4vw;
      border-radius: 0.5vw 0.5vw 0px 0px;
      margin-bottom: 0.3vw;

      @media all and (max-width: 1024px) {
        font-size: 20px;
        line-height: 10vw;
        border-radius: 1.5vw 1.5vw 0px 0px;
      }
    }
    .news-content {
      padding-top: 1vw;
      background: linear-gradient(to right, #867c67 0%,#665a49 15%,#190900 70%,#000000 100%);
      border-radius: 0px 0px 0.5vw 0.5vw;
      padding-bottom: 2vw;
      position: relative;

      @media all and (max-width: 1024px) {
        border-radius: 0px 0px 1.5vw 1.5vw;
        padding-bottom: 4vw;
      }
      .news-list {
        display: flex;
        padding: 0vw 2vw;
        cursor: pointer;
        @media all and (max-width: 1024px) {
          padding: 0vw 4vw;
        }
        .news-a {
          display: flex;
          width: 100%;
          border-bottom: 1px solid #fbf1b9;
          padding: 1vw 0vw;
          .news-date {
            @include dateText();
            flex: 1 1 15%;

            @media all and (max-width: 1440px) {
              align-self: center;
              // font-size: 16px;
            }

            @media all and (max-width: 1024px) {
              padding: 1.5vw 0vw;
              flex: 1 1 46%;
            }
          }

          .news-text {
            @include content();
            color: #fff;
            letter-spacing: 0.1em;
            font-size: 1vw;
            font-weight: 300;
            font-family: "微軟正黑體";
            flex: 1 1 80%;
            @media all and (max-width: 1920px) {
              font-size: 20px;
              // font-size: 16px;
            }
            @media all and (max-width: 1440px) {
              align-self: center;
              // font-size: 16px;
            }

            @media all and (max-width: 1024px) {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              // font-size: 3.7vw;
              margin-left: 3vw;
            }
            @media all and (max-width: 500px) {
              font-size: 3.7vw;
            }
          }

          .news-icon {
            display: block;
            align-self: flex-end;

            @media all and (max-width: 1024px) {
              align-self: center;
            }
            img {
              width: 30px;
              @media all and (max-width: 1920px) {
                width: 20px;
              }
              @media all and (max-width: 1024px) {
                width: 20px;
              }
              @media all and (max-width: 500px) {
                width: 12px;
              }
            }
          }
        }
      }

      .news-bg {
        position: absolute;
        z-index: -1;
        width: 23%;
        right: 0px;
        bottom: 0px;
        filter: brightness(1.5);

        @media all and (max-width: 1024px) {
          width: 36%;
        }
      }
    }
  }
}
</style>
