<template>
  <div class="container">
    <div class="content">
      <div class="title-box">
        <div class="title-en">CONTACT US</div>
        <div class="title-zh">聯絡我們</div>
      </div>
      <div class="info-box">
        <div class="info-left">
          <div class="info-title">競賽報名、資料繳交及相關問題：</div>
          <div class="info">
            聯合線上 蔡先生<br />
            聯絡電話：(02)8692-5588 分機5500<br />
            電子郵件：hsichun.tsai@udngroup.com.tw<br />
            聯絡地址：新北市汐止區大同路一段369號3樓<br />
          </div>
        </div>

        <div class="info-right">
          <div class="info">Copyright © 2025 聯合線上 版權所有</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_mixin.scss";
.container {
  width: 100%;
  position: relative;
  display: flex;
  padding-top: 3vw;
  flex-direction: column;
  background-color: #262626;
  padding-bottom: 3vw;

  @media all and (max-width: 1024px) {
    background-color: #262626;
    padding-top: 15vw;
    padding-bottom: 15vw;
  }
  .content {
    width: 850px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    @media all and (max-width: 1440px) {
      width: 57%;
    }
    @media all and (max-width: 1024px) {
      width: 85%;
    }
    .title-box {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0 auto;
      @media all and (max-width: 1024px) {
        margin-bottom: 7vw;
      }
      .title-zh {
        @include titleZH();
        writing-mode: horizontal-tb;
        text-align: center;
        display: flex;
        justify-content: center;
        &::before {
          content: "";
          width: 5vw;
          display: inline-block;
          margin-right: 1vw;
          border-top: 1px solid #fff;
          align-self: center;
          @media all and (max-width: 1024px) {
            width: 20vw;
            margin-right: 6vw;
          }
        }
      }

      .title-en {
        @include titleEN();
        writing-mode: horizontal-tb;
        text-align: center;
      }
    }

    .info-box {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin: 0 auto;
      margin-top: 3vw;

      @media all and (max-width: 1440px) {
        width: 100%;
      }
      @media all and (max-width: 1024px) {
        flex-direction: column;
      }
      .info-left {
        width: 50%;
        @media all and (max-width: 1024px) {
          width: 100%;
        }
        .info-title {
          @include content();
          color: #fbf1b9;
        }

        .info {
          @include content();
          text-align: initial;
          @media all and (max-width: 1024px) {
            text-align: initial;
          }

          .link2023 {
            @include content();

            text-decoration: none;
            padding-bottom: 0.1vw;
            border-bottom: 1px solid #f9edda;

            &:hover {
              color: #5e7775;
              border-bottom: 1px solid #5e7775;
            }
          }
        }
      }

      .info-right {
        display: flex;
        justify-content: end;
        text-align: end;
        align-self: end;
        width: 50%;

        @media all and (max-width: 1024px) {
          width: 100%;
          align-self: center;
          justify-content: center;
          margin-top: 6vw;
        }
        .info {
          @include content();
        }
      }
    }
  }
}
</style>
