<template>
  <div class="container" id="reviweteam">

    <div class="title-box">
      <div class="title">
        <div class="title-en">REVIEW TEAM</div>
        <div class="title-zh" value="2">評審團</div>
      </div>
      <div class="sub-title">
        <div
          class="international"
          :key="1"
          :class="{ select: current === 1 }"
          @click="selected(1)"
        >
          <div class="text">國際組</div>
          <div class="line">
            <img
              src="../assets/images/2025/team_active_point@2x.png"
              alt=""
              srcset=""
              v-if="!isMobile"
              v-show="current === 1"
            />
            <img
              src="../assets/images/2025/team_point@2x.png"
              alt=""
              srcset=""
              v-if="!isMobile"
              v-show="current !== 1"
            />
            <img
              src="../assets/images/2025/team_active_point_ph@2x.png"
              alt=""
              srcset=""
              v-if="isMobile"
              v-show="current == 1"
            />
            <img
              src="../assets/images/2025/team_point_ph@2x.png"
              alt=""
              srcset=""
              v-if="isMobile"
              v-show="current !== 1"
            />
          </div>
        </div>
        <div
          class="sustainable"
          :key="2"
          :class="{ select: current === 2 }"
          @click="selected(2)"
        >
          <div class="text" value="1">永續組</div>
          <div class="line">
            <img
              src="../assets/images/2025/team_active_point@2x.png"
              alt=""
              srcset=""
              v-if="!isMobile"
              v-show="current === 2"
            />
            <img
              src="../assets/images/2025/team_point@2x.png"
              alt=""
              srcset=""
              v-if="!isMobile"
              v-show="current !== 2"
            />
            <img
              src="../assets/images/2025/team_active_point_ph@2x.png"
              alt=""
              srcset=""
              v-if="isMobile"
              v-show="current == 2"
            />
            <img
              src="../assets/images/2025/team_point_ph@2x.png"
              alt=""
              srcset=""
              v-if="isMobile"
              v-show="current !== 2"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="team-content">
        <div class="first-team" v-show="current === 1">
          <div
            class="person"
            v-for="(item, index) in internationalPersonList"
            :key="index"
            :item="item"
          >
            <div class="photo">
              <img :src="item.jud_img" class="team1" alt="team1" srcset="" />
            </div>
            <div class="name">{{ item.jud_name }}</div>
            <div class="text">{{ item.jud_title }}</div>
          </div>
        </div>
        <div class="first-team" v-show="current === 2">
          <div
            class="person"
            v-for="(item, index) in sustainablePersonList"
            :key="index"
            :item="item"
          >
            <div class="photo">
              <img :src="item.jud_img" class="team1" alt="team1" srcset="" />
            </div>
            <div class="name">{{ item.jud_name }}</div>
            <div class="text">{{ item.jud_title }}</div>
          </div>
        </div>
      </div>

      <div class="orgainzer-content">
        <div class="title-box">
          <div class="title-en">ORGANIZER</div>
          <div class="title-zh">籌辦單位</div>
        </div>

        <div class="logo-content">
          <img
            src="../assets/images/2025/LOGO@2x.png"
            alt="logo"
            srcset=""
            v-if="!isMobile"
          />
          <img
            src="../assets/images/2025/logo_moblie@2x.png"
            alt="logo_moblie"
            srcset=""
            v-if="isMobile"
          />
        </div>
      </div>


    </div>
    <img
      class="team-bg2"
      src="../assets/images/2025/p8_img1@2x.webp"
      alt="team_bg2"
      srcset=""
      v-if="!isMobile"
    />
  </div>
</template>

<script>
import axios from "axios";
import gsap from 'gsap'

export default {
  name: "ReviewTeam",
  data() {
    return {
      isMobile: true,
      current: 1,
      internationalPersonList: [{}],
      sustainablePersonList: [{}],
    };
  },

  mounted() {
    this.onResize();
    this.getTeamInfo();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 1024;
    },
    selected(id) {
      this.current = id;
      this.BtnColor = true;
    },

    getTeamInfo() {
      axios
        .get("https://back-longterm.srl.tw/ajax/index.php?type=get_judge", {})
        .then((res) => {
          // 永續組 val: 1 國際組 val: 2
          this.internationalPersonList = res.data.data[0];
          this.sustainablePersonList = res.data.data[1];
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  watch: {
    current(){
      // console.log(newValue);
      gsap.from('.first-team', {opacity:0, y:40, duration:0.6, ease:'power1.out'});
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_mixin.scss";

.container {
  width: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
  margin-top: 3vw;
  padding-bottom: 5vw;
  @media all and (max-width: 1024px) {
    flex-direction: column;
    margin-top: 15vw;
    padding-bottom: 10vw;
  }
  .team-bg2 {
    position: absolute;
    width: 27%;
    bottom: 0vw;
    left: 0vw;
    z-index: 0;
  }
  .team-bg2-moblie {
    position: absolute;
    z-index: 0;
    width: 90%;
    top: 0vw;
    right: -25vw;
  }

  .title-box {
    display: flex;
    z-index: 2;
    width: 21%;
    padding-top: 3vw;
    flex-direction: column;
    align-items: center;
    @media all and (max-width: 1024px) {
      width: 85%;
      flex-direction: column;
      margin: 0 auto;
      text-align: center;
      margin-bottom: 10vw;
      z-index: 2;
    }
    .title {
      display: flex;
      @media all and (max-width: 1024px) {
        flex-direction: column;
      }
      .title-zh {
        @include titleZH();
        @media all and (max-width: 1024px) {
          justify-content: center;
          text-align: center;
          display: flex;
        }
        &::after {
          @include titleAfter();
          height: 8vw;
          @media all and (max-width: 1024px) {
            display: none;
          }
        }

        &::before {
          content: "";
          width: 24vw;
          display: none;
          margin-right: 6vw;
          border-top: 1px solid #fff;
          align-self: center;
          @media all and (max-width: 1024px) {
            display: inline-block;
          }
        }
      }

      .title-en {
        @include titleEN();
      }
    }

    .sub-title {
      display: flex;
      flex-direction: column;
      align-items: end;
      margin-right: 2vw;
      margin-top: 3vw;

      @media all and (max-width: 1024px) {
        flex-direction: row;
        margin-right: 0vw;
        margin-top: 5vw;
      }
      .international {
        cursor: pointer;
        display: flex;
        margin-bottom: 1.5vw;
        z-index: 2;
        width: 2vw;

        @media all and (max-width: 1024px) {
          margin-bottom: 0vw;
          flex-direction: column;
          align-items: flex-end;
          width: initial;
        }
        .text {
          @include content();
          writing-mode: vertical-lr;

          @media all and (max-width: 1024px) {
            writing-mode: horizontal-tb;
          }
        }

        .line {
          margin-left: 0.3vw;

          img {
            height: 2.3vw;

            @media all and (max-width: 1920px) {
              height: 2.8vw;
            }

            @media all and (max-width: 1440px) {
              height: 3.5vw;
            }

            @media all and (max-width: 1024px) {
              height: 1.2vw;
              width: 13vw;
              // width: 13vw;
              margin-left: 0vw;
              display: block;
            }
          }
        }
      }

      .sustainable {
        cursor: pointer;
        display: flex;
        z-index: 2;
        width: 2vw;

        @media all and (max-width: 1024px) {
          margin-bottom: 0vw;
          flex-direction: column;
          align-items: flex-end;
          margin-left: 7vw;
          width: initial;
        }
        .text {
          @include content();
          writing-mode: vertical-lr;
          @media all and (max-width: 1024px) {
            writing-mode: horizontal-tb;
          }
        }

        .line {
          margin-left: 0.3vw;
          img {
            height: 2.3vw;
            @media all and (max-width: 1920px) {
              height: 2.8vw;
            }

            @media all and (max-width: 1440px) {
              height: 3.5vw;
            }

            @media all and (max-width: 1024px) {
              height: 1.2vw;
              width: 13vw;
              margin-left: 0vw;
              display: block;
            }
          }
        }
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    width: 72%;

    @media all and (max-width: 1280px) {
      width: 53%;
    }
    @media all and (max-width: 1024px) {
      width: 85%;
      margin: 0 auto;
      z-index: 2;
    }

    .team-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 3vw;
      z-index: 10;
      .first-team {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 3vw;
        @media all and (max-width: 1024px) {
          justify-content: space-between;
        }
        .person {
          display: flex;
          width: 21%;
          flex-direction: column;
          margin-right: 1.5vw;
          position: relative;
          @media all and (max-width: 1440px) {
            margin-right: 0vw;
            width: 33%;
          }
          @media all and (max-width: 1024px) {
            width: 45%;
          }
          &::after {
            content: "";
            position: absolute;
            top: -0.5vw;
            right: 3.4vw;
            width: 7vw;
            height: 9vw;
            border-radius: 0.5vw;
            border: 1px solid #fbf1b9;

            @media all and (max-width: 1024px) {
              width: 30vw;
              height: 37vw;
              right: 2.2vw;
              top: -2vw;
              border-radius: 2vw;
            }
          }

          .photo {
            text-align: center;
            margin-bottom: 1vw;
            display: inline-block;
            position: relative;
            width: 7vw;
            height: 9vw;
            overflow: hidden;
            margin: 0 auto;
            border-radius: 0.5vw;
            z-index: 2;
            @media all and (max-width: 1024px) {
              width: 30vw;
              height: 37vw;
              border-radius: 2vw;
            }
            img {
              width: 10vw;
              height: 10vw;
              object-fit: contain;
              margin-left: -25%;
              @media all and (max-width: 1024px) {
                width: 42vw;
                height: 40vw;
                margin-left: -20%;
              }
            }
          }
          .name {
            @include dateText();
            font-family: "Noto Sans TC";
            font-weight: 400;
            text-align: center;
            margin-bottom: 0.1vw;
            margin-top: 1vw;

            @media all and (max-width: 1024px) {
              margin-top: 3vw;
              margin-bottom: 0.8vw;
            }
          }

          .text {
            @include content();
            text-align: center;
            width: 100%;
            padding-bottom: 2.5vw;
            @media all and (max-width: 1440px) {
              font-size: 14px;
            }

            @media all and (max-width: 1024px) {
              padding-bottom: 13vw;
              display: flex;
              justify-content: center;
            }
          }
        }
      }
      .team {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .person {
          display: flex;
          flex-direction: column;
          @media all and (max-width: 1024px) {
            width: 50%;
            margin-bottom: 8vw;
          }
          .photo {
            text-align: center;
            margin-bottom: 1vw;
            img {
              width: 70%;
            }
          }
          .name {
            @include dateText();
            font-weight: bold;
            text-align: center;
            margin-bottom: 0.1vw;
          }

          .text {
            @include content();
            text-align: center;
          }
        }
      }
    }

    .orgainzer-content {
      // padding-top: 4vw;

      @media all and (max-width: 1024px) {
        padding-top: 5vw;
      }
      .title-box {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin: 0 auto;
        @media all and (max-width: 1024px) {
          width: 85%;
          flex-direction: column;
          margin: 0 auto;
          text-align: center;
          margin-bottom: 10vw;
          z-index: 2;
        }
        .title-zh {
          @include titleZH();
          writing-mode: horizontal-tb;
          text-align: right;
          display: flex;
          justify-content: right;
          @media all and (max-width: 1024px) {
            justify-content: center;
            text-align: center;
            display: flex;
          }
          &::before {
            content: "";
            width: 5vw;
            display: inline-block;
            margin-right: 1vw;
            border-top: 1px solid #fff;
            align-self: center;
            @media all and (max-width: 1024px) {
              width: 20vw;
              margin-right: 6vw;
            }
          }
        }

        .title-en {
          @include titleEN();
          writing-mode: horizontal-tb;
          text-align: left;
          padding-right: 5vw;
          @media all and (max-width: 1024px) {
            padding-right: 5vw;
          }
        }
      }

      .logo-content {
        display: flex;
        justify-content: center;
        margin-top: 4vw;
        @media all and (max-width: 1024px) {
          margin-top: 0vw;
        }
        img {
          width: 75%;

          @media all and (max-width: 1440px) {
            width: 100%;
          }

          @media all and (max-width: 1024px) {
            width: 90%;
          }
        }
      }
    }
  }

  
}
</style>
