<template>
  <div class="container">
    

    <div class="title-box">
      <div class="title-en">ORIGIN</div>
      <div class="title-zh">緣起與發展</div>
    </div>
    <div class="content-box">
      <div class="content-title" v-if="!isMobile">
        <!-- <div class="title">
          放眼全球、實踐地球永續，龍騰微笑獎要當企業的導航
        </div>
        <div class="name">宏碁基金會董事長 陳俊聖</div> -->
        <img
          class="origin-subtitle"
          src="../assets/images/origin_subtitle.svg"
          alt=""
          srcset=""
        />
      </div>

      <div class="content-title-moblie" v-if="isMobile">
        <img
          class="origin-subtitle"
          src="../assets/images/origin_subtitle_moblie.png"
          alt=""
          srcset=""
        />
      </div>

      <div class="content">
        <div class="content1">
          宏碁基金會設立這個計畫以來，一路上持續邀請企業與我們討論，期望能夠透過這個獎項接觸各領域好手，無論是否發展到一定規模，都能放眼全球、接軌國際永續進程，於社會責任、環境緯度及公司治理落實企業經營。
        </div>
        <div class="content2">
          宏碁在辦獎過程不斷改變，也反應企業轉變的必經之路。從創意出發、再到注重產品層面，接著到目前要求的國際競爭力，隨著環境變化一路前行，這才是一個未來的企業。我們希望從宏碁自身做起，提高標準及自我要求，對產業乃至台灣產生影響，讓企業可以透過龍騰獎真正進入市場，找到適合自己的地方發光發熱。
        </div>
        <div class="content3">
          宏碁希望在大家創業初期就已放眼全球，在往目標前進的過程中可能發生錯誤，龍騰微笑獎扮演導航，我們期盼和企業產生連結，並透過這個連結幫助新創從中找到自身的利基點，做到
          「你可以別人不行」。
        </div>
      </div>
    </div>
    <img
      class="origin-bg"
      src="../assets/images/2025/p5_img1_1@2x.webp"
      alt="origin_bg"
      srcset=""
    />
 
  </div>
</template>

<script>
export default {
  name: "OriginCard",
  data() {
    return {
      isMobile: true,
    };
  },

  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 1024;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_mixin.scss";
.container {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 6vw;

  @media all and (max-width: 1024px) {
    flex-direction: column;
    margin: 0;
    padding-top: 60vw;
    overflow: hidden;
  }

  .origin-moblie-bg {
    display: flex;
    width: 65%;
    margin-left: -5vw;
    z-index: 1;

    @media all and (max-width: 1024px) {
      width: 40%;
    }
  }

  .origin-moblie-bg2 {
    position: absolute;
    width: 60%;
    right: 0px;
    top: 37vw;
    z-index: 0;

    @media all and (max-width: 1024px) {
      top: 13vw;
    }
  }
  .title-box {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 14%;

    @media all and (max-width: 1024px) {
      width: 85%;
      flex-direction: column;
      text-align: center;
      z-index: 2;
      position: relative;
      left: 0;
      margin: 0 auto;
      margin-bottom: 9vw;
    }
    .title-zh {
      @include titleZH();

      @media all and (max-width: 1024px) {
        display: flex;
        justify-content: center;
      }

      &::before {
        content: "";
        width: 24vw;
        display: none;
        margin-right: 6vw;
        border-top: 1px solid #fff;
        align-self: center;
        @media all and (max-width: 1024px) {
          display: inline-block;
        }
      }
    }
    .title-en {
      @include titleEN();
      &::after {
        @include titleAfter();
        height: 5vw;

        @media all and (max-width: 1920px) {
          height: 7vw;
        }

        @media all and (max-width: 1024px) {
          display: none;
        }
      }
    }
  }

  .content-box {
    width: 47%;
    padding-top: 3vw;

    @media all and (max-width: 1024px) {
      width: 85%;
      margin: 0 auto;
      padding-top: 0vw;
    }
    .content-title {
      display: flex;
      flex-direction: column;
      // width: 83.2%;
      margin-bottom: 2vw;

      @media all and (max-width: 1920px) {
        width: 100%;
      }
      .title {
        display: flex;
        justify-content: right;
        @include subTitle();
      }

      .name {
        @include pt16Text();
        color: #fbf1b9;
        font-weight: bold;
        justify-content: right;
        letter-spacing: 0.1em;
        text-shadow: #384c47c9 0.1em 0.1em 0.2em;
        display: flex;
        margin-top: 1vw;

        &::before {
          content: "";
          width: 5vw;
          display: inline-block;
          border-top: 1px solid #fbf1b9;
          margin-right: 1vw;
          align-self: center;
        }
      }

      .origin-subtitle {
        width: 85%;

        

        @media all and (max-width: 1440px) {
          width: 115%;
        }
      }
    }
    .content-title-moblie {
      display: flex;
      flex-direction: column;
      margin-bottom: 6vw;
      img {
        width: 100%;
      }
    }

    .content {
      @include content();
      @media all and (max-width: 1024px) {
        line-height: 6.5vw;
      }
      .content1,
      .content2 {
        margin-bottom: 2vw;

        @media all and (max-width: 1024px) {
          margin-bottom: 5vw;
        }
      }
    }
  }

  .origin-bg {
    position: absolute;
    right: 5%;
    width: 37%;
    top: -34%;

    @media (max-width:1024px) {
      left: 20%;
      width: 90%;
      top: -4%;
    }
  }
}
</style>
